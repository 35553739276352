<template>
    <el-row :gutter="20"
        class="center-list">
        <!-- 添加 -->
        <el-col :span="12">
            <router-link 
                :to="{name: editRouter}"
                class="center-list-add">
                <i class="el-icon-plus"></i>
                <span>
                    <slot name="addTips">
                        添加新服务
                    </slot>
                </span>
            </router-link>
        </el-col>
        <!-- 列表 -->
        <el-col :span="12"
            v-for="(item, index) in listData"
            :key="index">
            <slot name="listItem"
                :item="item">
            </slot>
        </el-col>
    </el-row>
</template>

<script>
    export default {
        props: {
            listData: Array,
            editRouter: {
                type: String,
                default: ""
            }
        }
    }
</script>

<style scoped>
.center-list > .el-col{
    height: 180px;
    margin-bottom: 10px;
}
.center-list-add{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 2px dashed #E4E7ED;
    border-radius: 4px;
}
.center-list-add > i{
    font-size: 40px;
    color: #DCDFE6;
}
.center-list-add > span{
    margin-top: 10px;
    font-size: 16px;
    color: #909399;
}
</style>